.language-banner {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
    transition: all 0.3s ease;
  }
  
  .language-banner.closed .language-buttons {
    transform: scaleY(0);
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
  
  .language-buttons {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    display: flex;
    flex-direction: column;
    transform-origin: top;
    transition: transform 0.3s ease, opacity 0.3s ease;
    position: absolute;
    top: 100%;
    right: 0;
  }
  
  .language-buttons button {
    background-color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 5px;
    transition: background-color 0.3s ease;
  }
  
  .language-buttons button.active {
    background-color: #0056b3;
    color: #fff;
  }
  
  .language-buttons button:hover {
    background-color: #f0f0f0;
  }
  
  .toggle-button {
    background: #0056b3;
    border: none;
    color: white;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
  }
  
  .language-banner.open .toggle-button {
    transform: translateX(50px);
  }
  
  @media (max-width: 768px) {
    .language-banner {
      top: 10px;
      right: 10px;
    }
  
    .language-buttons {
      padding: 5px;
    }
  
    .language-buttons button {
      font-size: 14px;
      padding: 8px;
    }
  
    .toggle-button {
      font-size: 20px;
      padding: 8px;
    }
  }
  